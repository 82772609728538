import './App.css';
import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import axios from "axios";
import { useEffect } from "react";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import ReactGA from "react-ga4";

const Blog = lazy(() => import('./components/Blog'));
const Sodbrennen = lazy(() => import('./components/Sodbrennen'));
const Schweissausbrueche = lazy(() => import('./components/Schweissausbrueche'));
const Uebersaeuerung = lazy(() => import('./components/Uebersaeuerung'));
const BesserLernen = lazy(() => import('./components/BesserLernen'));
const Omega3 = lazy(() => import('./components/Omega3'));
const Gelenke = lazy(() => import('./components/Gelenke'));
const Melatonin = lazy(() => import('./components/Melatonin'));
const EisenNahrungsergaenzung = lazy(() => import('./components/EisenNahrungsergaenzung'));
const Schwangerschaftsvitamine = lazy(() => import('./components/Schwangerschaftsvitamine'));
const Laktoseintoleranz = lazy(() => import('./components/Laktoseintoleranz'));
const Fruktoseintoleranz = lazy(() => import('./components/Fruktoseintoleranz'));
const Mueckenstiche = lazy(() => import('./components/Mueckenstiche'));
const EisenLebensmittel = lazy(() => import('./components/EisenLebensmittel'));
const Datenschutz = lazy(() => import('./components/Datenschutz'));
const Impressum = lazy(() => import('./components/Impressum'));
const UeberUns = lazy(() => import('./components/UeberUns'));
const DigitalHealth = lazy(() => import('./components/DigitalHealth'));
const Allergie = lazy(() => import('./components/Allergie'));
const Haemorrhoiden = lazy(() => import('./components/Haemorrhoiden'));
const MaennlicheFruchtbarkeit = lazy(() => import('./components/MaennlicheFruchtbarkeit'));
const Scheidentrockenheit = lazy(() => import('./components/Scheidentrockenheit'));
const Kopflaeuse = lazy(() => import('./components/Kopflaeuse'));
const Mundgeruch = lazy(() => import('./components/Mundgeruch'));

//axios.defaults.baseURL = 'http://localhost:3000/';
axios.defaults.baseURL = 'https://gesundheitsberatung.digital/';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2163bf'
    },
    secondary: {
      main: '#fff'
    }
  }
});

function App() {
  useEffect(() => {
    CookieConsent.run({
      disablePageInteraction: true,
      mode: 'opt-in',
      guiOptions: {
        consentModal: {
            layout: 'box',
            position: 'middle center',
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: 'box',
            equalWeightButtons: true,
            flipButtons: false
        }
    },

      categories: {
          necessary: {
              enabled: true,  // this category is enabled by default
              readOnly: true  // this category cannot be disabled
          },
          analytics: {
            services: {
                ga: {
                    label: 'Google Analytics',
                    onAccept: () => {
                        ReactGA.initialize('G-LHD5HSTM8R');
                        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.location.title });
                    },
                    onReject: () => {
                        // disable ga
                    },
                    cookies: [
                        {
                            name: /^(_ga|_gid)/
                        }
                    ]
                },
            }
          }
      },
  
      language: {
          default: 'de',
          translations: {
              de: {
                  consentModal: {
                      title: 'Cookie-Einstellungen',
                      description: 'Wir verwenden Cookies auf unserer Webseite. Einige dieser Cookies sind für die Funktionen der Seite notwendig, andere werden zur Analyse verwendet.',
                      acceptAllBtn: 'Akzeptieren',
                      acceptNecessaryBtn: 'Ablehnen',
                      showPreferencesBtn: 'Anpassen',
                      footer: `<a href="/impressum" target="_blank">Impressum</a><a href="/datenschutz" target="_blank">Datenschutz</a>`,
                  },
                  preferencesModal: {
                      title: 'Cookie-Einstellungen verwalten',
                      acceptAllBtn: 'Alle Akzeptieren',
                      acceptNecessaryBtn: 'Ablehnen',
                      savePreferencesBtn: 'Aktuelle Auswahl akzeptieren',
                      closeIconLabel: 'Einstellungen schließen',
                      sections: [
                          {
                              title: 'Notwendige Cookies',
                              description: 'Diese Cookies sind für das reibungslose Funktionieren der Website unerlässlich und können nicht deaktiviert werden.',
  
                              //this field will generate a toggle linked to the 'necessary' category
                              linkedCategory: 'necessary'
                          },
                          {
                              title: 'Performance und Analyse',
                              description: 'Diese Cookies sammeln Informationen darüber, wie Sie unsere Website nutzen. Alle Daten sind anonymisiert und können nicht dazu verwendet werden, Sie zu identifizieren.',
                              linkedCategory: 'analytics'
                          },
                          {
                            description: '<a  target="_blank" href="/datenschutz">Datenschutz</a>',
                          },
                          {
                            description: '<a  target="_blank" href="/impressum">Impressum</a>',
                          }
                      ]
                  }
              }
          }
      }
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <Navbar/>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog/" element={
              <Suspense fallback={<>...</>}>
                <Blog />
              </Suspense>
             } />
            <Route path="/digital-health/" element={
              <Suspense fallback={<>...</>}>
                <DigitalHealth />
              </Suspense>
             } />
            <Route path="/was-sind-haemorrhoiden/" element={
              <Suspense fallback={<>...</>}>
                <Haemorrhoiden />
              </Suspense>
             } />
            <Route path="/hausmittel-gegen-mueckenstiche/" element={
              <Suspense fallback={<>...</>}>
                <Mueckenstiche />
              </Suspense>
             } />
            <Route path="/in-welchen-lebensmitteln-ist-eisen/" element={
              <Suspense fallback={<>...</>}>
                <EisenLebensmittel />
              </Suspense>
             } />
            <Route path="/sodbrennen/" element={
              <Suspense fallback={<>...</>}>
                <Sodbrennen />
              </Suspense>
             } />
            <Route path="/schweissausbrueche/" element={
              <Suspense fallback={<>...</>}>
                <Schweissausbrueche />
              </Suspense>
             } />
            <Route path="/uebersaeuerung/" element={
              <Suspense fallback={<>...</>}>
                <Uebersaeuerung />
              </Suspense>
             } />
            <Route path="/besser-lernen/" element={
              <Suspense fallback={<>...</>}>
                <BesserLernen />
              </Suspense>
             } />
            <Route path="/wofuer-ist-omega-3-gut/" element={
              <Suspense fallback={<>...</>}>
                <Omega3 />
              </Suspense>
             } />
            <Route path="/was-hilft-bei-gelenkschmerzen/" element={
              <Suspense fallback={<>...</>}>
                <Gelenke />
              </Suspense>
             } />
            <Route path="/eisen-nahrungsergaenzung/" element={
              <Suspense fallback={<>...</>}>
                <EisenNahrungsergaenzung />
              </Suspense>
             } />
            <Route path="/fuer-was-ist-melatonin/" element={
              <Suspense fallback={<>...</>}>
                <Melatonin />
              </Suspense>
             } />
            <Route path="/schwangerschaftsvitamine/" element={
              <Suspense fallback={<>...</>}>
                <Schwangerschaftsvitamine />
              </Suspense>
             } />
            <Route path="/was-kann-man-gegen-laktoseintoleranz-machen/" element={
              <Suspense fallback={<>...</>}>
                <Laktoseintoleranz />
              </Suspense>
             } />
             <Route path="/was-hilft-gegen-kopflaeuse/" element={
              <Suspense fallback={<>...</>}>
                <Kopflaeuse />
              </Suspense>
             } />
             <Route path="/was-kann-man-gegen-fruktoseintoleranz-machen/" element={
              <Suspense fallback={<>...</>}>
                <Fruktoseintoleranz />
              </Suspense>
             } />
            <Route path="/maennliche-fruchtbarkeit/" element={
              <Suspense fallback={<>...</>}>
                <MaennlicheFruchtbarkeit />
              </Suspense>
            } />
            <Route path="/was-hilft-bei-scheidentrockenheit/" element={
              <Suspense fallback={<>...</>}>
                <Scheidentrockenheit />
              </Suspense>
            } />
            <Route path="/was-hilft-gegen-mundgeruch/" element={
              <Suspense fallback={<>...</>}>
                <Mundgeruch />
              </Suspense>
            } />
            <Route path="/unterschied-allergie-und-intoleranz/" element={
              <Suspense fallback={<>...</>}>
                <Allergie />
              </Suspense>
            } />
            <Route path="/ueber-uns/" element={
              <Suspense fallback={<>...</>}>
                <UeberUns />
              </Suspense>
            } />
            <Route path="/impressum/" element={
              <Suspense fallback={<>...</>}>
                <Impressum />
              </Suspense>
            } />
            <Route path="/datenschutz/" element={
              <Suspense fallback={<>...</>}>
                <Datenschutz />
              </Suspense>
            } />
          </Routes>
          <Footer/>
        </Router>
        
      </div>
    </ThemeProvider>
  );
}

export default App;
