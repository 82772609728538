import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";
import {library} from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Container from '@mui/material/Container';
library.add(fas, fab);
const Footer = (props) => {

  return (
    <div className="footer">
      <Container maxWidth="lg">
          <Grid container spacing={2} className="categoriesContainer">
                
                <Grid item xs={12} sm={4} md={3} className="footerColumn">
                  <h3 className="footerElement">Navigation</h3>
                  {/*<div className="footerElement"><Link to="/">Home</Link></div>*/}
                  <div className="footerElement"><Link to="/" onClick={e => {let categories = document.getElementById("categories"); categories && categories.scrollIntoView({ behavior: "smooth", block: "start"});  }}>Gesundheitsberatungen</Link></div>
                  <div className="footerElement"><Link to="/blog/">Blog</Link></div>
                  <div className="footerElement"><Link to="/ueber-uns/">Über uns</Link></div>
                  {/*<div className="footerElement"><Link to="/">Über uns</Link></div>*/}
                </Grid>
                <Grid item xs={12} sm={4} md={3}  className="footerElement footerColumn">
                  <h3>Follow Us</h3>
                  {/*<a href="https://www.facebook.com/DailyAdviceDe/" title="Facebook Profil" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon className="footerIcon" icon={['fab', 'facebook-square']}/>
                  </a>*/}
                  <a href="https://x.com/gesundheit16305" title="X Profil" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon className="footerIcon" icon={['fab', 'fa-square-x-twitter']}/>
                  </a>
                  <a href="https://www.instagram.com/gesundheitsberatung.digital" title="Instagram Profil" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon className="footerIcon" icon={['fab', 'instagram-square']}/>
                  </a>
                  <a href="https://www.threads.net/@gesundheitsberatung.digital" target="_blank" title="Threads Profil" rel="noopener noreferrer">
                    <FontAwesomeIcon className="footerIcon" icon={['fab', 'fa-square-threads']}/>
                  </a>
                  <a href="https://www.facebook.com/profile.php?id=61562803444240" target="_blank" title="Facebook Profil" rel="noopener noreferrer">
                    <FontAwesomeIcon className="footerIcon" icon={['fab', 'facebook-square']}/>
                  </a>
                </Grid>
                <Grid item xs={12} sm={4} md={3} className="footerColumn">
                  <h3 className="footerElement">Allgemeines</h3>
                  <div className="footerElement"><Link to="/impressum/">Impressum</Link></div>
                  <div className="footerElement"><Link to="/datenschutz/">Datenschutz</Link></div>
                  
                </Grid>
                <Grid item xs={12} sm={4} md={3} className="footerColumn">
                  {/*<h3 className="footerElement">Info</h3>
                  <div className="footerElement">Mit Sternchen (*) gekennzeichnete Links sind Affiliate-Links (Werbe-Links). Sollten Sie über diese Links einen Artikel erwerben, erhalten wir eine kleine Provision. Der Preis ändert sich für Sie dabei nicht.</div>*/}
                </Grid>
            </Grid> 
        </Container>
      </div>
  );
}

export default Footer;