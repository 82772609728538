const BackgroundDark = (props) => {
    return (
        <div>
          <div className="background"><img id="backgroundImageDark" 
          srcSet={`${props.imageSmall} 600w, ${props.imageMedium} 900w, ${props.image} 1920w`} alt="Apotheke Gesundheitsberatung Tresen"/></div>
          <div className="overlayDark"></div>
        </div>
    )
}

export default BackgroundDark;