import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import {library} from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Grid from '@mui/material/Grid';
library.add(fas)

const CardIconLeft = (props) => {
  const theme = useTheme();

  return (
    <Card sx={{ display: 'flex' }} elevation={0} className="cardContainer">
        <Grid container spacing={6}>
            <Grid item xs={12} sm={4} className="cardIconTextContainer">
                <FontAwesomeIcon className="cardIconLeft" icon={['fas', props.icon]}/>
            </Grid>
            <Grid item xs={12} sm={8} className="cardTextGridContainerLeft">
                <div className="cardTextContainer">
                    <h2>{props.headline}</h2>
                    {props.text}
                </div>
                
            </Grid>
            
        </Grid>
    </Card>
  );
}

export default CardIconLeft;