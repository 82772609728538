import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Logo from '../logo_white.webp';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import React, { useState } from "react";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fab from '@mui/material/Fab';
import Fade from '@mui/material/Fade';
import {library} from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'
import LogoBlack from "../img/logo_black.webp";
library.add(fas)

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 99 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger1 = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    threshold: 0
  });

  return (
    <Slide className={`${!trigger1 ? "navBar" : "navBarScrolled"}`} appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};  

export default function HideAppBar(props) {
  const [navStatus, setNavStatus] = useState(false);
  
  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar className="navBar">
        <div className="navbar">
          <div className="container nav-container">
          <Link to="/" title="Logo Home" className="logoLink"><div className="logoContainer" style={{
            backgroundImage: `url(${Logo})`
          }}></div></Link>
              <div className="desktopNav">
              <Link to="/" onClick={e => {
  let categories = document.getElementById("categories");
  categories && categories.scrollIntoView({ behavior: "smooth", block: "start"});
  }}>
              <Button
                key={1}
                sx={{ my: 2, color: 'black', display: 'inline-block' }}
              >
                Gesundheitsberatungen
              </Button>
              
              </Link>
              <Link to="/blog/">
                <Button
                  key={1}
                  sx={{ my: 2, color: 'black', display: 'inline-block' }}
                >
                  Blog
                </Button>
              </Link>
              <Link to="/ueber-uns/">
                <Button
                  key={1}
                  sx={{ my: 2, color: 'black', display: 'inline-block' }}
                >
                  Über uns
                </Button>
              </Link>
              </div>
              <div className={navStatus ? 'hamburger-lines checked' : 'hamburger-lines'} onClick={e => {setNavStatus(prevCheck => !prevCheck);}}>
                <span className="line line1"></span>
                <span className="line line2"></span>
                <span className="line line3"></span>
              </div>
              
            <div className={navStatus ? 'menu-items active' : 'menu-items'}>
              <ul>
              <li><Link to="/" onClick={e => {
  let categories = document.getElementById("categories");
  categories && categories.scrollIntoView({ behavior: "smooth", block: "start"});
  }}>
              <Button
                key={1}
                onClick={e => {setNavStatus(prevCheck => !prevCheck);}}
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                Gesundheitsberatungen
              </Button>
              </Link>
              </li><li>
              <Link to="/blog/">
                <Button
                  key={1}
                  onClick={e => {setNavStatus(prevCheck => !prevCheck);}}
                  sx={{ my: 2, color: 'black', display: 'block' }}
                >
                  Blog 
                </Button>
              </Link></li>
              <li>
              <Link to="/ueber-uns/">
                <Button
                  key={1}
                  onClick={e => {setNavStatus(prevCheck => !prevCheck);}}
                  sx={{ my: 2, color: 'black', display: 'block' }}
                >
                  Über uns 
                </Button>
              </Link></li>
              </ul>
              <div className="menuLogoContainer">
                <img src={LogoBlack} className="menuLogo" alt="Menu Logo"/>
              </div>
             

              
            </div>
          </div>
        </div>

        </AppBar>
      </HideOnScroll>
      <ScrollTop {...props}>
        <Fab size="small" id="scrollTopButton" aria-label="scroll back to top" onClick={(e) => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}