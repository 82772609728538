import { Button } from '@mui/material';
import BackgroundImage from "../img/background.webp";
import BackgroundImageSmall from "../img/background_small.webp";
import BackgroundImageMedium from "../img/background_medium.webp";
import { Link } from "react-router-dom";
import BackgroundDark from "../components/BackgroundDark";
import ListDetails from "../components/ListDetails";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CardIconRight from "./CardIconRight";
import CardIconLeft from "./CardIconLeft";
import Section from "../components/Section";
import Logo from '../logo.webp';
import React, { useLayoutEffect } from "react";
import CountUp from 'react-countup';
import { lazy, Suspense } from 'react';
import {Helmet} from "react-helmet";

const Categories = lazy(() => import('../components/Categories'));


const Home = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const listElements = ['kostenlos', 'vollumfänglich', 'ganzheitlich', 'anonym', 'Rund um die Uhr', 'Beratung nach Bedürfnissen'];
    const listIcons = ['money-bill', 'globe', 'circle','user-secret',  'clock', 'users']
    return (
        <div>
          <BackgroundDark image={BackgroundImage} imageSmall={BackgroundImageSmall} imageMedium={BackgroundImageMedium}/>        
          <Helmet>
              <title>Digitale Gesundheitsberatung | gesundheitsberatung.digital</title>
              <meta name="description" content="Finde ganzheitliche Gesundheitsberatungen - kostenlos und anonym - und das am besten zu dir passende Produkt. Gemeinsam gesünder!" />
              <link rel="canonical" href="https://gesundheitsberatung.digital/" />
          </Helmet> 
          <div className="landingContentContainer">
            <div>Willkommen bei der digitalen, ganzheitlichen Gesundheitsberatung</div>
            <br/>
            <div>Wie können wir Ihnen helfen?</div>
            <br/>
            <div className="categorySelectionContainer">
            <Link to="/" onClick={e => {
  let categories = document.getElementById("categories");
  e.preventDefault();  // Stop Page Reloading
  categories && categories.scrollIntoView({ behavior: "smooth", block: "start"});
  }}><Button variant="outlined" size="large" className="problemButton" color="secondary">Beschwerde auswählen</Button></Link>
            </div>
          </div>
          <Container maxWidth="lg" >
          <br/>
          <h1>Ganzheitliche Gesundheitsberatung</h1>
            <Grid container spacing={6} className="counterContainer">
              <Grid item xs={12} md={3} className="counterItemContainer">
                <span className="counterItem">&#62;<CountUp end={15} /></span><br/>Beratungen
              </Grid>
              <Grid item xs={12} md={3} className="counterItemContainer">
                <span className="counterItem">&#62;<CountUp end={269} /></span><br/>Detailfragen
              </Grid>
              <Grid item xs={12} md={3} className="counterItemContainer">
                <span className="counterItem">&#62;<CountUp end={125} /></span><br/>Produkte
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              Sie möchten einfach und kostenlos zu Gesundheitsthemen beraten werden? Eine Beratung in einer öffentlichen Apotheke ist Ihnen unangenehm oder schlichtweg nicht möglich? Anonymität und Diskretion ist Ihnen wichtig? Dann sind Sie hier genau richtig!
            </Grid>
            <br/>
            <Suspense fallback={<>...</>}>
                <Categories />
            </Suspense>
            <Grid container spacing={6} className="categoriesContainer">
              <Grid item xs={12} md={12}>
                <Section headline="Was bieten wir an?" text="Auf unserer Seite finden Sie kostenlose, ganzheitliche Gesundheitsberatungen zu vielfältigen Themen. Die Beratungen sind von erfahrenen Apothekern erstellt und getestet worden. Alle Produkte werden unverbindlich und ohne Einflussnahme von den Herstellern empfohlen. Neben den Beratungen finden Sie zusätzlich eine Vielzahl unterschiedlicher Artikel zum Thema ganzheitliche Gesundheit in unserem Blog." />
              </Grid>
              <Grid item xs={12} md={12}>
                <CardIconLeft icon="gear" headline="Wie funktioniert unsere Gesundheitsberatung?" text="Suchen Sie sich in den Kategorien einfach das gewünschte Thema aus, zu dem Sie beraten werden möchten. Lesen Sie die Informationstexte zum besseren Verständnis und beantworten Sie anschließend die aufkommenden Fragen. Anhand Ihrer Antworten suchen wir die für Sie ideale Lösung oder empfehlen Ihnen das für Sie am besten geeignete Produkt." />
              </Grid>
              <Grid item xs={12} md={12}>
                <Section headline="Welchen Nutzen hat unsere digitale Gesundheitsberatung?" text="Alle Beratungen können rund um die Uhr und bequem von daheim aufgerufen werden. Niemand muss sich bei uns nach Öffnungszeiten richten. Ziel unserer ganzheitlichen Gesundheitsberatung ist nicht nur die Linderung von Symptomen, sondern mit die Ursache der Symptome zu finden und zu beseitigen. Durch die detaillierte Erstellung werden kleinste Aspekte in der Beratung berücksichtigt, die ein entscheidender Faktor bei der Ursachenforschung sein können.
  Es geht nicht zwanghaft darum ein Produkt zu empfehlen. Und sollte es doch so sein, wird Ihnen nicht das nach Preis-Leistung-Verhältnis vermeintlich ideale Produkt vorgeschlagen, sondern das speziell für Ihre Bedürfnisse am besten Geeignete. Unsere Gesundheitsberatung nimmt Ihnen dabei die erschwerliche Suche nach dem passenden Produkt ab.
  Durch die Anonymität unserer Gesundheitsberatung erhalten Sie auch zu Ihnen eventuell unangenehmen Themen kompetente Auskünfte.
  Und das beste ist: Alle Beratungen sind völlig kostenlos. Während der Beratung wird Ihnen kein Kaufzwang auferlegt. Sie können die Beratung jederzeit stoppen, weiterführen oder auch abbrechen. Das Angebot ist absolut unverbindlich." />
              </Grid>
              <Grid item xs={12} md={12}>
                <CardIconRight icon="money-bill" headline="Warum kostenlose Gesundheitsberatung?" text="Jeder Mensch hat das Recht auf eine gute Beratung. Personen, die aufgrund geringer Löhne und steigender Lebenshaltungskosten die vor Ort aufgerufenen Preise nicht mehr bezahlen können, sollen dennoch die Möglichkeit auf eine gute Beratung bekommen. Uns ist es wichtig, dass auch diejenigen ein passendes Produkt finden, die auf günstigere Preise im Internet angewiesen sind. Gesundheit ist schließlich das höchste Gut eines jeden Menschen." />
              </Grid>
              <Grid item xs={12} md={12}>
                <Section headline="Das Ziel der ganzheitlichen Gesundheitsberatung" text="Primäres Ziel unserer kostenlosen Beratungen ist die Berücksichtigung der ganzheitlichen Gesundheit. Sollte sich in der Beratung rausstellen, dass Symptome lediglich die Folge eines ungesunden Lebensstils sind, empfehlen wir Ihnen kein Produkt, es sei denn, es wird von Ihnen ausdrücklich gewünscht. Unsere ganzheitlichen Gesundheitsberatungen zielen nicht auf eine rein symptomatische Behandlung der Beschwerden ab, sondern vielmehr auf die Beseitigung der Ursachen.
  Durch die stetige Zusammenarbeit mit unseren beratenden Apothekern befinden wir uns stets auf dem aktuellen Stand. Neue wissenschaftliche Erkenntnisse sowie Produktneuerscheinungen werden schnellstmöglich berücksichtigt. " />
              </Grid>
              <Grid item xs={12} md={12}>
                <Section headline="Nutzen der ganzheitlichen Gesundheitsberatung" text="Wieso Produkte kaufen, wenn es gar nicht notwendig ist? Wieso Nebenwirkungen von Medikamenten akzeptieren, wenn eine Umstellung des Lebensstils eine signifikante Verbesserung der Beschwerden und eine Reduzierung der Medikamente die Folge hätte? Vielen Menschen ist gar nicht bewusst, welchen Einfluss der Lebensstil auf die Gesundheit des Körpers hat. Viele scheuen aber auch die eigenen Ess- oder Lebensgewohnheit zu hinterfragen. Uns ist es wichtig die Menschen in unseren ganzheitlichen Gesundheitsberatungen hierauf hinzuweisen. Niemand würde einem kranken Fisch in einem kleinen verschmutzten Aquarium mit verdrecktem Wasser lediglich Medikamente geben. Niemand würde sich um ihn kümmern und anschließend wieder in das dreckige Wasser zurücksetzen. Ein jeder würde das Aquarium reinigen und das Wasser austauschen. Das Wasser dieses Fisches verhält sich wie unsere Umgebung. Ändern wir nicht die Umgebung wie z.B. den Lebensstil, kommen die Symptome immer wieder. " />
              </Grid>
              <Grid item xs={12} md={6} className="advantagesList" >
                <ListDetails headline="Vorteile der digitalen Gesundheitsberatung:" details={listElements} icons={listIcons}/>
              </Grid>
              <Grid item xs={12} md={6}>
                <Section headline="Gute Gesundheitsberatung" text="Uns ist wichtig zu erwähnen, dass die verschiedenen ganzheitlichen Gesundheitsberatungen keinen Ersatz für eine professionelle Behandlung und/ oder Beratung durch einen Arzt darstellen. Aufgrund unseren getätigten Empfehlungen dürfen ohne Rücksprache mit einem Arzt medikamentöse Behandlungen nicht unterbrochen werden und keine pharmakologische Therapien gestartet werden. Zudem geben wir bewusst keine Gesundheitsberatungen zu schweren Erkrankungen, die nur unter Einsatz verschreibungspflichtiger Medikamente behandelt werden können. Hierzu zählen unter anderem die diversen Krebsarten." />
              </Grid>
              <Grid item xs={12} md={12}>
                <Section headline="" text="Sie vermissen ein Beratungsthema? Dann schreiben Sie uns gerne eine Mail. Wir werden versuchen Ihnen zu Ihren gewünschten Themen schnellstmöglich einen Informationstext und eine kostenlose Gesundheitsberatung zur Verfügung zu stellen. Ihr Team von gesundheitsberatung.digital." />
              </Grid>  
              <Grid item xs={12} md={12}>
                <div className="sloganContainer"><img src={Logo} alt="Logo"/><br/>Gemeinsam gesünder</div>   
              </Grid>
                 
            </Grid>
          </Container>
          
        </div>
    )
}

export default Home;

