import * as React from 'react';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import {library} from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

library.add(fas)

const ListDetails = (props) => {
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        
        <div className="listHeadline"><h2>{props.headline}</h2></div>
        
        <table className="advantagesTable">
          <tbody>
            { props.details.map((detail, index) => (
              <tr key={index}>
                <td><FontAwesomeIcon icon={['fas', props.icons[index]]} /></td>
                <td><ListItemText primary={detail} /></td>
              </tr>      
                      ))}

          </tbody>
        </table>
      
    
    </Box>
  );
}

export default ListDetails;