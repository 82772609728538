const Section = (props) => {
    return (
        
        <div className="section">
            {props.headline ? <h2>{props.headline}</h2> : ''}
            {props.subheadline ? <h3>{props.subheadline}</h3> : ''}
            {props.text}</div>
    )
}

export default Section;